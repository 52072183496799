import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { BasePureComponent } from "common/components/Base";
import { Breadcrumbs } from "common/components/Breadcrumbs";
import { getSessionId } from "common/util/session";
import { createLoadable } from "common/Loadable";

// load components dynamically
const Home = createLoadable(() => import("app/scenes/Home"));
const SetPassword = createLoadable(() => import("app/scenes/SetPassword"));
const Info = createLoadable(() => import("common/scenes/Info"));
const Account = createLoadable(() => import("app/scenes/Account"));
const Administrators = createLoadable(() =>
  import("app/scenes/Administrators")
);
const AdministratorReports = createLoadable(() =>
  import("app/scenes/AdministratorReports")
);
const Administrator = createLoadable(() =>
  import("app/scenes/Administrators/scenes/Administrator")
);
const Sites = createLoadable(() => import("app/scenes/Sites"));
const Site = createLoadable(() => import("app/scenes/Sites/scenes/Site"));
const AssignSiteStaff = createLoadable(() =>
  import("app/scenes/AssignSiteStaff")
);
const Event = createLoadable(() =>
  import("app/scenes/Sites/scenes/Site/scenes/Events/scenes/Event")
);
const Guardians = createLoadable(() => import("app/scenes/Guardians"));
const Guardian = createLoadable(() =>
  import("app/scenes/Guardians/scenes/Guardian")
);
const Volunteers = createLoadable(() => import("app/scenes/Volunteers"));
const Volunteer = createLoadable(() =>
  import("app/scenes/Volunteers/scenes/Volunteer")
);
const VolunteerOrganizations = createLoadable(() =>
  import("app/scenes/VolunteerOrganizations")
);
const VolunteerOrganization = createLoadable(() =>
  import("app/scenes/VolunteerOrganizations/scenes/VolunteerOrganization")
);
const Scholars = createLoadable(() => import("app/scenes/Scholars"));
const Scholar = createLoadable(() =>
  import("app/scenes/Scholars/scenes/Scholar")
);
const ScholarApplications = createLoadable(() =>
  import("app/scenes/ScholarApplications")
);
const ScholarApplication = createLoadable(() =>
  import("app/scenes/ScholarApplications/scenes/ScholarApplication")
);
const SiteStaff = createLoadable(() => import("app/scenes/SiteStaff"));
const SiteStaffer = createLoadable(() =>
  import("app/scenes/SiteStaff/scenes/SiteStaffer")
);
const SiteStaffApplications = createLoadable(() =>
  import("app/scenes/SiteStaffApplications")
);
const SiteStaffApplication = createLoadable(() =>
  import("app/scenes/SiteStaffApplications/scenes/SiteStaffApplication")
);
const SiteStaffEmployeeRecords = createLoadable(() =>
  import("app/scenes/SiteStaffEmployeeRecords")
);
const SiteStaffEmployeeRecord = createLoadable(() =>
  import("app/scenes/SiteStaffEmployeeRecords/scenes/SiteStaffEmployeeRecord")
);
const SiteStaffAttendance = createLoadable(() =>
  import("app/scenes/SiteStaffAttendance")
);
const Activities = createLoadable(() => import("app/scenes/Activities"));
const Activity = createLoadable(() =>
  import("app/scenes/Activities/scenes/Activity")
);
const AllActivities = createLoadable(() =>
  import("app/scenes/Activities/scenes/AllActivities")
);
const SiteReports = createLoadable(() => import("app/scenes/SiteReports"));
const ScholarReports = createLoadable(() =>
  import("app/scenes/ScholarReports")
);
const SiteStaffReports = createLoadable(() =>
  import("app/scenes/SiteStaffReports")
);
const Configuration = createLoadable(() => import("app/scenes/Configuration"));
const ScholarBusSchedule = createLoadable(() =>
  import("app/scenes/ScholarBusSchedule")
);

/*
 * The application's main content. This content changes as the user navigates.
 * Note that routes need to be ordered from most specific to least specific.
 */
class Main extends BasePureComponent {
  render() {
    // parent
    super.render();

    // for securing some routes behind login
    const loggedIn = getSessionId() && this.props.administrator ? true : false;
    const secureRoute = (component) => {
      return loggedIn ? (
        component
      ) : (
        <Redirect
          to={{
            pathname:
              "/" +
              (this.props.location &&
              this.props.location.pathname &&
              this.props.location.pathname.length > 0
                ? "~" + encodeURIComponent(this.props.location.pathname)
                : ""),
          }}
        />
      );
    };

    return (
      <div className="fsp-main">
        <div className="fsp-page">
          {loggedIn && <Breadcrumbs current={this.props.location.pathname} />}
          <Switch>
            {/* set password */}
            <Route exact path="/password/:token" component={SetPassword} />

            {/* application info */}
            <Route
              exact
              path="/info"
              render={(props) =>
                secureRoute(<Info {...this.props} {...props} />)
              }
            />

            {/* profile management */}
            <Route
              exact
              path="/account"
              render={(props) =>
                secureRoute(<Account {...this.props} {...props} />)
              }
            />

            {/* admin management */}
            <Route
              exact
              path="/administrators/administrator/:administratorId!:tab"
              render={(props) =>
                secureRoute(<Administrator {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/administrators/administrator/:administratorId"
              render={(props) =>
                secureRoute(<Administrator {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/administrators/administrator"
              render={(props) =>
                secureRoute(<Administrator {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/administrators"
              render={(props) =>
                secureRoute(<Administrators {...this.props} {...props} />)
              }
            />

            {/* site management */}
            <Route
              exact
              path="/sites/site/:siteId/events/event/:siteYearId/:eventId!:tab"
              render={(props) =>
                secureRoute(<Event {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/sites/site/:siteId/events/event/:siteYearId/:eventId"
              render={(props) =>
                secureRoute(<Event {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/sites/site/:siteId/events/event"
              render={(props) =>
                secureRoute(<Event {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/sites/site/:siteId!:tab"
              render={(props) =>
                secureRoute(<Site {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/sites/site/:siteId"
              render={(props) =>
                secureRoute(<Site {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/sites/site"
              render={(props) =>
                secureRoute(<Site {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/sites"
              render={(props) =>
                secureRoute(<Sites {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/assignSiteStaff!:tab"
              render={(props) =>
                secureRoute(<AssignSiteStaff {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/assignSiteStaff"
              render={(props) =>
                secureRoute(<AssignSiteStaff {...this.props} {...props} />)
              }
            />

            {/* guardian management */}
            <Route
              exact
              path="/guardians/guardian/:guardianId!:tab/:entityId"
              render={(props) =>
                secureRoute(<Guardian {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/guardians/guardian/:guardianId!:tab"
              render={(props) =>
                secureRoute(<Guardian {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/guardians/guardian/:guardianId"
              render={(props) =>
                secureRoute(<Guardian {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/guardians/guardian"
              render={(props) =>
                secureRoute(<Guardian {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/guardians"
              render={(props) =>
                secureRoute(<Guardians {...this.props} {...props} />)
              }
            />

            {/* scholar management */}
            <Route
              exact
              path="/scholars/scholar/:guardianId/:scholarId!:tab"
              render={(props) =>
                secureRoute(<Scholar {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/scholars/scholar/:guardianId/:scholarId"
              render={(props) =>
                secureRoute(<Scholar {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/scholars"
              render={(props) =>
                secureRoute(<Scholars {...this.props} {...props} />)
              }
            />

            {/* scholar application management */}
            <Route
              exact
              path="/scholarApplications/scholarApplication/:guardianId/:scholarId/:scholarApplicationId!:tab"
              render={(props) =>
                secureRoute(<ScholarApplication {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/scholarApplications/scholarApplication/:guardianId/:scholarId/:scholarApplicationId"
              render={(props) =>
                secureRoute(<ScholarApplication {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/scholarApplications/scholarApplication/:guardianId/:scholarId"
              render={(props) =>
                secureRoute(<ScholarApplication {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/scholarApplications"
              render={(props) =>
                secureRoute(<ScholarApplications {...this.props} {...props} />)
              }
            />

            {/* scholar bus schedule */}
            <Route
              exact
              path="/scholarBusSchedule"
              render={(props) =>
                secureRoute(<ScholarBusSchedule {...this.props} {...props} />)
              }
            />

            {/* site staff management */}
            <Route
              exact
              path="/siteStaff/siteStaffer/:siteStaffId!:tab"
              render={(props) =>
                secureRoute(<SiteStaffer {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/siteStaff/siteStaffer/:siteStaffId"
              render={(props) =>
                secureRoute(<SiteStaffer {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/siteStaff"
              render={(props) =>
                secureRoute(<SiteStaff {...this.props} {...props} />)
              }
            />

            {/* site staff application management */}
            <Route
              exact
              path="/siteStaffApplications/siteStaffApplication/:siteStaffId/:siteStaffApplicationId!:tab"
              render={(props) =>
                secureRoute(<SiteStaffApplication {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/siteStaffApplications/siteStaffApplication/:siteStaffId/:siteStaffApplicationId"
              render={(props) =>
                secureRoute(<SiteStaffApplication {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/siteStaffApplications/siteStaffApplication/:siteStaffId"
              render={(props) =>
                secureRoute(<SiteStaffApplication {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/siteStaffApplications"
              render={(props) =>
                secureRoute(
                  <SiteStaffApplications {...this.props} {...props} />
                )
              }
            />

            {/* site staff employee record management */}
            <Route
              exact
              path="/siteStaffEmployeeRecords/siteStaffEmployeeRecord/:siteStaffId/:siteStaffEmployeeRecordId!:tab"
              render={(props) =>
                secureRoute(
                  <SiteStaffEmployeeRecord {...this.props} {...props} />
                )
              }
            />
            <Route
              exact
              path="/siteStaffEmployeeRecords/siteStaffEmployeeRecord/:siteStaffId/:siteStaffEmployeeRecordId"
              render={(props) =>
                secureRoute(
                  <SiteStaffEmployeeRecord {...this.props} {...props} />
                )
              }
            />
            <Route
              exact
              path="/siteStaffEmployeeRecords/siteStaffEmployeeRecord/:siteStaffId"
              render={(props) =>
                secureRoute(
                  <SiteStaffEmployeeRecord {...this.props} {...props} />
                )
              }
            />
            <Route
              exact
              path="/siteStaffEmployeeRecords"
              render={(props) =>
                secureRoute(
                  <SiteStaffEmployeeRecords {...this.props} {...props} />
                )
              }
            />

            {/* site staff attendance */}
            <Route
              exact
              path="/siteStaffAttendance!:tab"
              render={(props) =>
                secureRoute(<SiteStaffAttendance {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/siteStaffAttendance"
              render={(props) =>
                secureRoute(<SiteStaffAttendance {...this.props} {...props} />)
              }
            />

            {/* activity management */}
            <Route
              exact
              path="/activities/activity/:activityId!:tab"
              render={(props) =>
                secureRoute(<Activity {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/activities/activity/:activityId"
              render={(props) =>
                secureRoute(<Activity {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/activities/activity"
              render={(props) =>
                secureRoute(<Activity {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/activities"
              render={(props) =>
                secureRoute(<Activities {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/activities/all"
              render={(props) =>
                secureRoute(<AllActivities {...this.props} {...props} />)
              }
            />

            {/* reports: sites */}
            <Route
              exact
              path="/reports/sites/:reportId!:tab"
              render={(props) =>
                secureRoute(<SiteReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/sites/:reportId"
              render={(props) =>
                secureRoute(<SiteReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/sites"
              render={(props) =>
                secureRoute(<SiteReports {...this.props} {...props} />)
              }
            />

            {/* reports: scholars */}
            <Route
              exact
              path="/reports/scholars/:reportId!:tab"
              render={(props) =>
                secureRoute(<ScholarReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/scholars/:reportId"
              render={(props) =>
                secureRoute(<ScholarReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/scholars"
              render={(props) =>
                secureRoute(<ScholarReports {...this.props} {...props} />)
              }
            />

            {/* reports: site staff */}
            <Route
              exact
              path="/reports/siteStaff/:reportId!:tab"
              render={(props) =>
                secureRoute(<SiteStaffReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/siteStaff/:reportId"
              render={(props) =>
                secureRoute(<SiteStaffReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/siteStaff"
              render={(props) =>
                secureRoute(<SiteStaffReports {...this.props} {...props} />)
              }
            />

            {/* reports: administrators */}
            <Route
              exact
              path="/reports/administrators/:reportId!:tab"
              render={(props) =>
                secureRoute(<AdministratorReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/administrators/:reportId"
              render={(props) =>
                secureRoute(<AdministratorReports {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/reports/administrators"
              render={(props) =>
                secureRoute(<AdministratorReports {...this.props} {...props} />)
              }
            />

            {/* volunteer management */}
            <Route
              exact
              path="/volunteers/volunteer/:volunteerId!:tab/:entityId"
              render={(props) =>
                secureRoute(<Volunteer {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/volunteers/volunteer/:volunteerId!:tab"
              render={(props) =>
                secureRoute(<Volunteer {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/volunteers/volunteer/:volunteerId"
              render={(props) =>
                secureRoute(<Volunteer {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/volunteers/volunteer"
              render={(props) =>
                secureRoute(<Volunteer {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/volunteers"
              render={(props) =>
                secureRoute(<Volunteers {...this.props} {...props} />)
              }
            />

            {/* volunteer organizations */}
            <Route
              exact
              path="/volunteerOrganizations/volunteerOrganization/:volunteerOrganizationId"
              render={(props) =>
                secureRoute(
                  <VolunteerOrganization {...this.props} {...props} />
                )
              }
            />
            <Route
              exact
              path="/volunteerOrganizations/volunteerOrganization"
              render={(props) =>
                secureRoute(
                  <VolunteerOrganization {...this.props} {...props} />
                )
              }
            />
            <Route
              exact
              path="/volunteerOrganizations"
              render={(props) =>
                secureRoute(
                  <VolunteerOrganizations {...this.props} {...props} />
                )
              }
            />

            {/* configuration management */}
            <Route
              exact
              path="/config!:tab"
              render={(props) =>
                secureRoute(<Configuration {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/config"
              render={(props) =>
                secureRoute(<Configuration {...this.props} {...props} />)
              }
            />

            {/* redirect */}
            <Route
              exact
              path="/~:redirect"
              render={(props) => <Home {...this.props} {...props} />}
            />

            {/* default */}
            <Route render={(props) => <Home {...this.props} {...props} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

// make router props accessible; this is necessary to
// drive re-renders based on path changes
Main = withRouter(connect()(Main));

// set prop types and required-ness
Main.propTypes = { administrator: PropTypes.object };

export default Main;
