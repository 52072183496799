import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";

import { BasePureComponent } from "common/components/Base";
import HasPermission from "common/components/HasPermission";
import { hasPermission } from "common/util/session";
import i18n from "./i18n.json";

/* The application's menu. */
class Menu extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="fsp-menu">
        {/* group: site management */}
        <HasPermission
          or
          permissions={
            "viewSites,manageSites,viewCalendars,manageCalendars,manageClasses,viewGuardians"
          }
        >
          <div className="fsp-menu-group">
            <span className="fsp-menu-group-text">
              <Translate id="menu.groups.site.label" />
            </span>
          </div>

          {/* sites */}
          <HasPermission or permissions="viewSites,manageSites">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/sites"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.sites.${
                      hasPermission("manageSites") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.sites.${
                      hasPermission("manageSites") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* activities */}
          <HasPermission or permissions="viewCalendars,manageCalendars">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/activities"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.activities.${
                      hasPermission("manageCalendars") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.activities.${
                      hasPermission("manageCalendars") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* assign site staff */}
          <HasPermission permissions="viewSiteStaff">
            <HasPermission or permissions="manageSites,manageClasses">
              <div className="fsp-menu-item">
                <span className="fsp-menu-item-text">
                  <Link
                    to="/assignSiteStaff"
                    onClick={() => this.props.onClick()}
                    title={this.props.translate(
                      "menu.items.assignSiteStaff.manage.title"
                    )}
                  >
                    <Translate id="menu.items.assignSiteStaff.manage.label" />
                  </Link>
                </span>
              </div>
            </HasPermission>
          </HasPermission>

          {/* reports */}
          <HasPermission or permissions="viewSites,viewCalendars,viewGuardians">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/reports/sites"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate("menu.items.siteReports.title")}
                >
                  <Translate id="menu.items.siteReports.label" />
                </Link>
              </span>
            </div>
          </HasPermission>
        </HasPermission>

        {/* group: scholar */}
        <HasPermission
          or
          permissions={
            "viewGuardians,manageGuardians," +
            "viewScholars,manageScholars," +
            "viewScholarApplications,manageScholarApplications"
          }
        >
          <div className="fsp-menu-group">
            <span className="fsp-menu-group-text">
              <Translate id="menu.groups.scholar.label" />
            </span>
          </div>

          {/* guardians */}
          <HasPermission or permissions="viewGuardians,manageGuardians">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/guardians"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.guardians.${
                      hasPermission("manageGuardians") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.guardians.${
                      hasPermission("manageGuardians") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* scholars */}
          <HasPermission or permissions="viewScholars,manageScholars">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/scholars"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.scholars.${
                      hasPermission("manageScholars") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.scholars.${
                      hasPermission("manageScholars") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* scholar applications */}
          <HasPermission
            or
            permissions="viewScholarApplications,manageScholarApplications"
          >
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/scholarApplications"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.scholarApplications.${
                      hasPermission("manageScholarApplications")
                        ? "manage"
                        : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.scholarApplications.${
                      hasPermission("manageScholarApplications")
                        ? "manage"
                        : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* scholar bus schedule */}
          <HasPermission permissions="manageScholars">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/scholarBusSchedule"
                  onClick={() => this.props.onClick()}
                  title="menu.items.scholarBusSchedule.title"
                >
                  <Translate id="menu.items.scholarBusSchedule.label" />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* reports */}
          <HasPermission or permissions="viewScholars,viewScholarApplications">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/reports/scholars"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    "menu.items.scholarReports.title"
                  )}
                >
                  <Translate id="menu.items.scholarReports.label" />
                </Link>
              </span>
            </div>
          </HasPermission>
        </HasPermission>

        {/* group: site staff management */}
        <HasPermission
          or
          permissions={
            "viewSiteStaff,manageSiteStaff," +
            "viewSiteStaffApplications,manageSiteStaffApplications," +
            "viewSiteStaffEmployeeRecords,manageSiteStaffEmployeeRecords"
          }
        >
          <div className="fsp-menu-group">
            <span className="fsp-menu-group-text">
              <Translate id="menu.groups.siteStaff.label" />
            </span>
          </div>

          {/* site staff */}
          <HasPermission or permissions="viewSiteStaff,manageSiteStaff">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/siteStaff"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.siteStaff.${
                      hasPermission("manageSiteStaff") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.siteStaff.${
                      hasPermission("manageSiteStaff") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* site staff applications */}
          <HasPermission
            or
            permissions="viewSiteStaffApplications,manageSiteStaffApplications"
          >
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/siteStaffApplications"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.siteStaffApplications.${
                      hasPermission("manageSiteStaffApplications")
                        ? "manage"
                        : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.siteStaffApplications.${
                      hasPermission("manageSiteStaffApplications")
                        ? "manage"
                        : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* site staff employee records */}
          <HasPermission
            or
            permissions="viewSiteStaffEmployeeRecords,manageSiteStaffEmployeeRecords"
          >
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/siteStaffEmployeeRecords"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.siteStaffEmployeeRecords.${
                      hasPermission("manageSiteStaffEmployeeRecords")
                        ? "manage"
                        : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.siteStaffEmployeeRecords.${
                      hasPermission("manageSiteStaffEmployeeRecords")
                        ? "manage"
                        : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* site staff attendance */}
          <HasPermission or={false} permissions="viewSites,manageSiteStaff">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/siteStaffAttendance"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    "menu.items.siteStaffAttendance.manage.title"
                  )}
                >
                  <Translate id="menu.items.siteStaffAttendance.manage.label" />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* reports */}
          <HasPermission or permissions="viewSiteStaff">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/reports/siteStaff"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    "menu.items.siteStaffReports.title"
                  )}
                >
                  <Translate id="menu.items.siteStaffReports.label" />
                </Link>
              </span>
            </div>
          </HasPermission>
        </HasPermission>

        {/* group: volunteer management */}
        <HasPermission or permissions={"viewVolunteers,manageVolunteers"}>
          <div className="fsp-menu-group">
            <span className="fsp-menu-group-text">
              <Translate id="menu.groups.volunteer.label" />
            </span>
          </div>

          {/* volunteers */}
          <HasPermission or permissions="viewVolunteers,manageVolunteers">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/volunteers"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.volunteers.${
                      hasPermission("manageVolunteers") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.volunteers.${
                      hasPermission("manageVolunteers") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* volunteer organizations */}
          <HasPermission or permissions="viewVolunteers,manageVolunteers">
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/volunteerOrganizations"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.volunteerOrganizations.${
                      hasPermission("manageVolunteers") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.volunteerOrganizations.${
                      hasPermission("manageVolunteers") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>
        </HasPermission>

        {/* group: system management */}
        <HasPermission
          or
          permissions={
            "viewAdministrators,manageAdministrators," +
            "viewConfig,manageConfig,viewContent,manageContent"
          }
        >
          <div className="fsp-menu-group">
            <span className="fsp-menu-group-text">
              <Translate id="menu.groups.system.label" />
            </span>
          </div>

          {/* administrators */}
          <HasPermission
            or
            permissions="viewAdministrators,manageAdministrators"
          >
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text text-truncate">
                <Link
                  to="/administrators"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.administrators.${
                      hasPermission("manageAdministrators") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.administrators.${
                      hasPermission("manageAdministrators") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* config */}
          <HasPermission
            or
            permissions="viewConfig,manageConfig,viewContent,manageContent"
          >
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/config"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    `menu.items.configuration.${
                      hasPermission("manageConfig") ? "manage" : "view"
                    }.title`
                  )}
                >
                  <Translate
                    id={`menu.items.configuration.${
                      hasPermission("manageConfig") ? "manage" : "view"
                    }.label`}
                  />
                </Link>
              </span>
            </div>
          </HasPermission>

          {/* reports */}
          <HasPermission
            or
            permissions="viewAdministrators,manageAdministrators"
          >
            <div className="fsp-menu-item">
              <span className="fsp-menu-item-text">
                <Link
                  to="/reports/administrators"
                  onClick={() => this.props.onClick()}
                  title={this.props.translate(
                    "menu.items.administratorReports.title"
                  )}
                >
                  <Translate id="menu.items.administratorReports.label" />
                </Link>
              </span>
            </div>
          </HasPermission>
        </HasPermission>

        {/* group: user */}
        <div className="fsp-menu-group">
          <span className="fsp-menu-group-text">
            <Translate id="menu.groups.user.label" />
          </span>
        </div>

        {/* profile */}
        <div className="fsp-menu-item">
          <span className="fsp-menu-item-text">
            <Link
              to="/account"
              onClick={() => this.props.onClick()}
              title={this.props.translate("menu.items.account.title")}
            >
              <Translate id="menu.items.account.label" />
            </Link>
          </span>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Menu = withLocalize(connect()(Menu));

// set prop types and required-ness
Menu.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Menu;
